import React from 'react';
import RenderContent from '../../RenderContent'
import { decodeEntities } from '../../../utils/helpers'
import GatsbyLink from '../../GatsbyLink'
import Heading from '../../Heading/Heading';
import './TextColumnsWithIntro.scss';

export const TextColumnsWithIntro = props => {
  const { title, introContent, actions, columns } = props;
  return (
    <section className="text-columns-with-intro">
      <div className="wrap">
        {title && <Heading tag="h2" className="section-title center">{decodeEntities(title)}</Heading>}
        {introContent && <div className="intro-content" dangerouslySetInnerHTML={{__html: decodeEntities(introContent)}} />}
        {actions && actions.length > 0 && (
          <div className="actions">
            {actions.map(action => action.link && (
              <GatsbyLink key={action.link.url} to={action.link.url} className="action" decode>{action.link.title}</GatsbyLink>
            ))}
          </div>
        )}
        {columns && columns.length > 0 && (
          <div className="columns-container">
            {columns.map(column => (
              <div className="column" key={column.title}>
                {column.title && <h2 className="section-title" dangerouslySetInnerHTML={{__html: decodeEntities(column.title)}} />}
                {column.text && <RenderContent className="content" content={column.text} />}
                {column.link && <GatsbyLink to={column.link.url} className="action" decode>{column.link.title}</GatsbyLink>}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
