import React from 'react';
import RenderContent from '../../RenderContent'
import { decodeEntities } from '../../../utils/helpers'
import GatsbyLink from '../../GatsbyLink'
import Heading from '../../Heading/Heading';
import './TextColumns.scss';

export const TextColumns = props => {
  const { columns } = props;
  return (
    <section className="text-columns">
      <div className="wrap">
        {columns && columns.length > 0 && (
          <div className="columns-container">
            {columns.map(column => (
              <div className="column" key={column.title}>
                {column.title && <Heading tag="h3" className="section-title">{decodeEntities(column.title)}</Heading>}
                {column.text && <RenderContent className="content" content={column.text} />}
                {column.link && <GatsbyLink to={column.link.url} className="action" decode>{column.link.title}</GatsbyLink>}
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};
