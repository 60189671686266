import React from 'react';
import Leads from '../../Leads';
// import PipedriveForm from '../../PipedriveForm';
import { decodeEntities } from '../../../utils/helpers';
import Heading from '../../Heading/Heading';
import './ContactForm.scss';

export const ContactForm = (props) => {
  const {
    title,
    intro,
    form_id
  } = props;

  let lead_form_id;

  // default form
  if (form_id) {
    lead_form_id = form_id;
  } else {
    lead_form_id = 15; /* fallback: default contact form */
  }

  return (
    <>
    
    {/*<section className="contact-form" id="Contact">
      <div className="wrap">
        <div className="contact-form-intro">
          {title && <Heading tag="h2" className="section-title center">{decodeEntities(title)}</Heading>}
           {intro && <p className="intro">{decodeEntities(intro)}</p>} 
          parse intro text as html instead
          {intro && <p className="intro" dangerouslySetInnerHTML={{ __html: intro }} /> }
        </div>
         <PipedriveForm />
        <Leads id={lead_form_id} />
      </div>
  </section>*/}
  </>
  )
}
