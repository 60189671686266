import React, { Fragment } from 'react';
import OnVisible from 'react-on-visible';
import { Image } from '../../Image';
import GatsbyLink from '../../GatsbyLink'
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { Breadcrumbs } from '../../Breadcrumbs'
import { decodeEntities } from '../../../utils/helpers'
import Heading from '../../Heading/Heading';
import './Header.scss';

export const Header = (props) => {
  const {
    breadcrumbs,
    title,
    title_heading_level,
    headerContent,
    actions,
    mediaType,
    image,
    image_option,
    image_alt_text,
    video,
    html_video_source,
    html_video_poster,
    location
  } = props;
  const isHome = location.pathname === '/';
  console.log("debug header", props);
  return (
    <section className={`page-header${isHome ? ' homepage' : ''}`}>
      <div className="wrap">
        <div className="title-block">
          <div className="inner">
            {breadcrumbs &&
              <Breadcrumbs location={location} />
            }

            {title_heading_level === ''
              ? <Heading tag="h1" className="page-title">{decodeEntities(title)}</Heading>
              : <Heading tag={title_heading_level} className="page-title">{decodeEntities(title)}</Heading>
            }

            {headerContent && <div className="header-content" dangerouslySetInnerHTML={{ __html: headerContent }} />}
            {actions && actions.length > 0 && (
              <div className="actions">
                {actions.map((action, index) => action.link && (
                  <Fragment key={index}>
                    {action.link.url === '#Contact' ? (<AnchorLink to={action.link.url} className="scroll-action" title="Contact">
                      {action.link.title}
                    </AnchorLink>) : <GatsbyLink key={action.link.url} to={action.link.url} className="action" decode>{action.link.title}</GatsbyLink>}
                  </Fragment>
                ))}
              </div>
            )}
          </div>
        </div>
        <div className="image-container">

          {mediaType === 'image' && image_option != true &&  // default - add Gatsby image
            <Image className="image" src={image} alt={image_alt_text} fetchpriority="high" loading="eager"/>
          }

          {mediaType === 'image' && image_option === true && // alternative - add image with eager loading
            <Image className="image" src={image} alt={image_alt_text} fetchpriority="high" loading="eager" />
            // <img className="image" src={'https://wp.revell.com.au/' + image.source_url} alt={image_alt_text} loading="eager" />
          }

          {mediaType === 'video' && // add iframe video (e.g. youtube video player)
            <div className="video">
              <iframe src={video} frameBorder="0" allowFullScreen />
            </div>
          }
       
          {mediaType === 'html_video' && // add html video
            <div className="video">
              <video controls poster={'https://wp.revell.com.au' + html_video_poster.source_url} preload="metadata" width="768" height="432">
                <source src={'https://wp.revell.com.au' + html_video_source} type="video/mp4" />
              </video>
            </div>
          }

        </div>
      </div>
    </section>
  )
}
