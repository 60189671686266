import React from 'react'
import RenderContent from '../../RenderContent'
import './Text.scss'
import { Link } from 'gatsby'

export const Text = props => {
  const { contentBody } = props
  let isHome = ''
  if (props.location.pathname === '/') {
    isHome = true
  }
  return (
    <section className="text-block myclass">
      <div className="wrap">
        <div className="section-content">
          <RenderContent className="content" content={contentBody} />
        </div>
        {isHome && (
          <div className="actions">
            <a className="action" href="/contact-us/">
              Let's chat
            </a>
          </div>
        )}
      </div>
    </section>
  )
}
