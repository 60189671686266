import React from 'react';
import { decodeEntities } from '../../../utils/helpers';
import { ImageContentSlider } from '../../ImageContentSlider';
import Heading from '../../Heading/Heading';
import './ImageAndTextSlider.scss';

export const ImageAndTextSlider = props => {
  const { title, slides } = props;
  return (
    <section className="image-and-text-slider">
      <div className="wrap">
        {title && <Heading tag="h2" className="section-title">{decodeEntities(title)}</Heading>}
        <ImageContentSlider slides={slides} title={title} />
      </div>
    </section>
  );
};
