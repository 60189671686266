import React, { Fragment } from 'react';
import OnVisible from 'react-on-visible';
import GatsbyLink from '../../GatsbyLink'
import { decodeEntities } from '../../../utils/helpers'
import Heading from '../../Heading/Heading';
import './LargeVideoHeader.scss';

export const LargeVideoHeader = props => {
  const {
    video_url,
    video_poster_url,
    title,
    scroll_down_label,
    header_text_content
  } = props;
  return (
    <>
      <section className="large-video-header">

        <video autoPlay muted loop playsInline poster={'https://wp.revell.com.au' + video_poster_url.source_url} width="800" height="600">
          <source src={'https://wp.revell.com.au' + video_url} type="video/mp4" />
        </video>

        <div className="video-content-overlay">
          <div className="wrap">

            <Heading>{title}</Heading>
            
            <div className="wrap-inner">
              {header_text_content}
              <a className="video-overlay-action" href="#ContentAfterVideo">
                <span>{scroll_down_label}</span>
                <svg width="50" height="50" viewBox="0 0 50 50" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M25 50a25 25 0 1 0 0-50 25 25 0 0 0 0 50Zm1.5-40a1.5 1.5 0 0 0-3 0v26.4l-7.4-7.5a1.5 1.5 0 0 0-2.2 2.2l10 10c.6.5 1.6.5 2.2 0l10-10a1.5 1.5 0 0 0-2.2-2.2l-7.4 7.5V10Z" />
                </svg>
              </a>
            </div>

          </div>
        </div>

        
      </section>
      <span id="ContentAfterVideo"></span>
    </>
  )
}
