import React from 'react';
import { Image } from '../Image';
import Carousel from '../Carousel';
import RenderContent from '../RenderContent'
import GatsbyLink from '../GatsbyLink';
import { decodeEntities, slugify } from '../../utils/helpers';
import './ImageContentSlider.scss';

const SlideItem = props => {
  const { slide, className, count } = props;
  const { title, link, image, contentBody } = slide;
  return (
    <div className={className}>
      <div className="wrap">
        <div className="inner">
          <div className="image-container">
            {image && <Image src={image.source_url} className="image" />}
          </div>
          <div className="content-container">
            <div className="content">
              <span className="slide-counter">0{count + 1}</span>
              {title && <h2 className="section-title">{decodeEntities(title)}</h2>}
              {contentBody && <div className="content-body"><p>{decodeEntities(contentBody)}</p></div>}
              {link && <GatsbyLink to={link} className="action" decode>SEE PROJECT</GatsbyLink>}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const ImageContentSlider = props => {
  const { slides, title } = props;
    const settings = {
      container: `${slugify(title).substring(0,9)}-slug`,
      nav: true,
      controls:true,
      mouseDrag: true,
      items: 1,
      loop: true,
      speed: 700,
    };
    return (
      <div className="image-content-slider">
        <Carousel settings={settings}>
          {slides.map((slide, slideCount) => (slideCount < 6 && slide) &&
            <SlideItem count={slideCount} key={slideCount} slide={slide} className="slide" />
          )}
        </Carousel>
      </div>
    )
}