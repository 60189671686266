import React, { Component } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import GatsbyLink from '../GatsbyLink';
import RenderContent from '../RenderContent';
import Carousel from '../Carousel';
import './GoogleReviews.scss';
import { decodeEntities } from '../../utils/helpers';
import { render } from 'react-dom';

const isClient = typeof window !== 'undefined';

class GoogleReviews extends Component {

  // Gatsby plugin help
  // https://github.com/franklintarter/gatsby-source-google-places
  render() {
    const reviewData = this.props;
    const settings = {
      container: 'google-review-slider',
      nav: false,
      controls:true,
      mouseDrag: true,
      items: 3,
      loop: true,
      responsive: {
        0: {
          items: 1
        },
        800: {
          items: 2
        },
        1080: {
          items: 3
        }
      }
    };

    /*
    * Activate Read more/less on click
    */
    if (typeof window !== "undefined") {
      window.addEventListener('click', function (evt) {
        if (evt.target.classList.contains('read-more')) {
          evt.target.previousElementSibling.previousElementSibling.classList.toggle('active');
          if (evt.target.previousElementSibling.previousElementSibling.classList.contains('active')) {         
            evt.target.innerHTML = 'read less';
          } else {
            evt.target.innerHTML = 'read more'; 
          }
        }
      }, false);
    }
 
    const listItems = reviewData.data.googlePlacesPlace.childrenGooglePlacesReview.map(function(review, i) {
      let starRating = '';

      for (let i=0; i<5; i++) {
        if (i < review.rating) {
          starRating += '★';
        } else {
          starRating += '☆';
        }
      }
      
      let originalText = review.text;
      let stringLength = originalText.length;
      let additionalText = '<span class="read-group"><span class="read-dot">...</span><span class="read-text">';
      let position = 150;
      if (stringLength > position) {
        var output = [originalText.slice(0, position), additionalText, originalText.slice(position)].join('');
            output = output + '</span></span><br><a class="read-more">read more</a>';
      } else {
        var output = review.text;
      }

      {/* a single review */}
      return <div key={i} className="g-review">
        <p className="g-review-text" dangerouslySetInnerHTML={{__html: output}} />
        <p className="g-review-author">{review.author_name}</p>
        <p className="g-review-star-rating" title={review.rating + "/5"}>{starRating}</p>
        <p className="g-review-date">{review.relative_time_description}</p>
        {/* {review.profile_photo_url && <img src={review.profile_photo_url} />}   */}
      </div>
    });
 
    return (
      <section className="google-reviews">
        <div className="wrap">
          <h2 className="heading section-title center visible">Recent Google Reviews</h2>
          <h3 className="total-rating">
            <a href="https://g.page/revell-landscaping" title={reviewData.data.googlePlacesPlace.rating + "/5 rating"} target="_blank">
              {reviewData.data.googlePlacesPlace.name} &ndash; {reviewData.data.googlePlacesPlace.rating} ★★★★★
            </a>
          </h3>
          <Carousel settings={settings}>
            {listItems}
          </Carousel>
          {/* <ul className="list-google-reviews">{listItems}</ul> */}
          {/* <pre>{JSON.stringify(reviewData)}</pre> */}
        </div>
      </section>
    )
  };
}

export default props => (
  <StaticQuery
    query={graphql`
      query {
        googlePlacesPlace {
          name
          rating
          childrenGooglePlacesReview {
            author_name
            text
            rating
            relative_time_description
          }
          user_ratings_total
        }
      }
    `}
    // render={data => <GoogleReviews />}
    // render={data => <GoogleReviews data={data} {...props} />}
    render={data => <GoogleReviews data={data} props={props} />}
  />
)