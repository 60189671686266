import React, { Component } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import OnVisible from 'react-on-visible';
import './LogoFeed.scss';
import { Image } from '../../Image';
import Carousel from '../../Carousel';
import GatsbyLink from '../../GatsbyLink';
import Heading from '../../Heading/Heading';

class ThisSlider extends Component {
  render () {
    const { gallery } = this.props;
    const settings = {
      container:'logo-carousel-inner',
      loop: false,
      nav: false,
      controls: true,
      mouseDrag: true,
      gutter: 30,
      items: 6,
      center: false,
      responsive: {
        1600: {
          items: 6,
        },
        1080: {
          items: 5,
        },
        640: {
          items: 4,
        },
        360: {
          items: 2,
        }
      }
    };


    return (
      <div className="logo-carousel">
        <Carousel settings={settings}>
          {gallery && gallery.map((slide, slideCount) => {
            return (
              <div key={slideCount} className="slide">
                <div className="aspect">
                  <Image src={slide} className="slide-logo" />
                </div>
              </div>
            );
          })}
        </Carousel>
      </div>
    )
  }
}

export const LogoFeed = (props) => {
  const { LogoGallery: gallery, title, useGlobalLogos } = props;
  const { siteSettings: { options } } = useStaticQuery(graphql`
    query {
      siteSettings: wordpressAcfOptions(options: {}) {
        options {
          partners {
            source_url
            title
          }
        }
      },
    }
  `);
  const galleryItems = useGlobalLogos ? options.partners : gallery;
  return (
    <section className="logo-feed">
      {title && <div className="title"><Heading tag="h3" className="section-title center">{title}</Heading></div>}
      <div className="inner">
        <ThisSlider gallery={galleryItems} />
      </div>
    </section>
  )
}
