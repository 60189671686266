import React, { Fragment } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
// import Image from '../../Image';
import Heading from '../../Heading/Heading';
import GatsbyLink from '../../GatsbyLink'
import { decodeEntities } from '../../../utils/helpers'
import RenderContent from '../../RenderContent'
import './ProjectGalleryGrid.scss';

export const ProjectGalleryGrid = props => {
  const {
    heading,
    text_content,
    projects
  } = props;
  const { allWordpressWpProjects: { nodes: queriedProjects } } = useStaticQuery(graphql`
    {
      allWordpressWpProjects(sort: { order: DESC, fields: date }) {
        nodes {
          wordpressId: wordpress_id
          title
          link
          image: featured_image_url {
            source_url
          }
        }
      }
    }
  `);
  const projectsToDisplay = projects.map(p => queriedProjects.find(qp => qp.wordpressId === p));
  return (
    <>
      <section className="project-gallery-grid">
        <div className="wrap">
          <div className="project-grid-heading">
            {heading && <Heading tag="h2" className="section-title center">{decodeEntities(heading)}</Heading>}
            {text_content && <RenderContent className="content" content={text_content} />}
          </div>
        </div>
      </section>

      <section className="project-list">
        <div className="outer">
          <div className="wrap">
            <div className="inner">
              <div className="list">
        
                {projectsToDisplay.map(project => (
                  <div className="post-list-item" key={project.id}>     
                    <div>

                      <div className="image">
                        <GatsbyLink to={project.link} label={project.title}>
                          <img className="featured-project-image" src={'https://wp.revell.com.au' + project.image.source_url} alt={project.title} loading="lazy" />
                        </GatsbyLink>
                      </div>

                      <div className="inner">
                        <h4 className="title">
                          <GatsbyLink to={project.link}>{decodeEntities(project.title)}</GatsbyLink>
                        </h4>
                        <GatsbyLink to={project.link} label={project.title} className="post-link">see project</GatsbyLink>
                      </div>

                    </div>
                  </div>
                ))}

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}
