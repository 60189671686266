import React from 'react';
import OnVisible from 'react-on-visible';
import RenderContent from '../../RenderContent'
import { decodeEntities } from '../../../utils/helpers';
import { Image } from '../../Image';
import GatsbyLink from '../../GatsbyLink'
import Heading from '../../Heading/Heading';
import './ImageAndText.scss';

export const ImageAndText = props => {
  const {
    image,
    textPosition = 'left',
    title,
    contentBody,
    actions
  } = props;
  return (
    <section className="image-and-text-block">
      <div className={`wrap ${textPosition}`}>
        <OnVisible className="image-container">
          <Image src={image} className="image" />
        </OnVisible>
        <div className="text-container">
          {title && <Heading className={`section-title ${textPosition === 'center' ? 'center' : ''}`} tag="h2">{decodeEntities(title)}</Heading>}
          {contentBody && <RenderContent className="content" content={contentBody} />}
          {actions && actions.length > 0 && (
            <div className="actions">
              {actions.map(action => action.link && (
                <GatsbyLink key={action.link.url} to={action.link.url} className="action" decode>{action.link.title}</GatsbyLink>
              ))}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
