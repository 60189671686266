import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { decodeEntities } from '../../../utils/helpers';
import { ImageContentSlider } from '../../ImageContentSlider';
import Heading from '../../Heading/Heading';
import './ProjectFeed.scss';

export const ProjectFeed = props => {
  const { title, options, projects } = props;
  const { allWordpressWpProjects: { nodes: queriedProjects } } = useStaticQuery(graphql`
    {
      allWordpressWpProjects(sort: { order: DESC, fields: date }) {
        nodes {
          wordpressId: wordpress_id
          title
          link
          image: featured_image_url {
            source_url
          }
          categories {
            slug
          }
        }
      }
    }
  `);

  // exclude projects with the "3d-designs" category
  const filteredProjects = queriedProjects.filter(project =>
    !project.categories.some(category => category.slug === "3d-designs")
  );

  const projectsToDisplay = queriedProjects && options === 'recent' ?
    filteredProjects.slice(0,6) :
    projects.map(p => queriedProjects.find(qp => qp.wordpressId === p));
  return (
    <section className="project-feed">
      <div className="wrap">
        {title && <Heading tag="h2" className="section-title">{decodeEntities(title)}</Heading>}
        <ImageContentSlider slides={projectsToDisplay} title={title} />
      </div>
    </section>
  );
};
